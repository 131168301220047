<template>
	<div
		class="rule-statistic-card d-flex align-center justify-center flex-column rounded-lg"
		:style="`background-color:${backgroudColor}`"
	>
		<div class="title d-flex align-center">
			<v-icon size="25px" class="mr-1" color="white">mdi-{{ icon }}</v-icon>
			<h5 class="ortho-title">{{ title }}</h5>
		</div>
		<div class="numbers text-center">
			<span>
				<b>{{ numerator != null ? numerator : '-' }}/{{ denominator != null ? denominator : '-' }}</b>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RuleStatisticCard',
	props: {
		title: String,
		icon: String,
		backgroudColor: String,
		denominator: Number,
		numerator: Number,
	},
};
</script>

<style lang="scss" scoped>
.rule-statistic-card {
	padding: 1rem 1rem;
	width: 250px;
	.title h5 {
		font-size: 18px;
		color: white;
	}
	.numbers {
		font-size: 32px;
		color: white;
	}
}
</style>
