import { render, staticRenderFns } from "./FamilyTagRadarChart.vue?vue&type=template&id=07ad5fdf&scoped=true&"
import script from "./FamilyTagRadarChart.vue?vue&type=script&lang=js&"
export * from "./FamilyTagRadarChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ad5fdf",
  null
  
)

export default component.exports