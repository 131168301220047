<template>
	<Apexchart
		type="radar"
		:options="chartOptions"
		:series="[{ name: 'Skeleton data', data: [0, 0, 0, 0, 0] }]"
	></Apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import { defaultFamilyTagRadarChartConfig } from '@/components/reports/parcours/chartConfig';

export default {
	name: 'FamilyTagRadarChartSkeleton',
	components: { Apexchart: VueApexCharts },
	data() {
		return {
			chartOptions: { ...defaultFamilyTagRadarChartConfig },
		};
	},
};
</script>

<style lang="scss" scoped></style>
