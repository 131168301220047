<template>
	<div>
		<div v-if="loading"><FamilyTagRadarChartSkeleton /></div>
		<div v-else>
			<Apexchart type="radar" :options="chartOptions" :series="series"></Apexchart>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import FamilyTagRadarChartSkeleton from '@/components/skeletons/FamilyTagRadarChartSkeleton';
import { defaultFamilyTagRadarChartConfig } from './chartConfig';

export default {
	name: 'FamilyTagRadarChart',
	components: { Apexchart: VueApexCharts, FamilyTagRadarChartSkeleton },
	props: { ruleTagsMap: Map },
	computed: {
		...mapGetters('familyTags', { familyTags: 'familyTags' }),
	},
	data() {
		return {
			loading: true,
			chartOptions: { ...defaultFamilyTagRadarChartConfig },
			series: [{ name: 'Score percentage by Family tag', data: [0, 0, 0, 0, 0] }],
		};
	},
	async created() {
		if (this.familyTags == null) await this.$store.dispatch('familyTags/getAllFamilyTags');
		if (this.ruleTagsMap == null) return;
		this.calculateScorePerFamilyTag(this.ruleTagsMap, this.familyTags);
	},
	watch: {
		ruleTagsMap(newVal) {
			this.calculateScorePerFamilyTag(newVal, this.familyTags);
		},
	},
	methods: {
		updateChartData(data) {
			this.series[0].data = data;
		},
		buildFamilyTagsMap(ruleTagsMap, familyTagsArr) {
			const familyTagMap = new Map(); // Key is family name
			ruleTagsMap.forEach((ruleTag) => {
				const foundFamily = familyTagsArr.find((tag) => tag._id === ruleTag.family_tag_id);
				if (familyTagMap.has(foundFamily.name)) {
					familyTagMap.get(foundFamily.name).ruleTags.push({
						_id: ruleTag._id,
						name: ruleTag.name,
						point_target: ruleTag.point_target,
						totalPointUserEarned: ruleTag.totalStoryPoint,
					});
				} else {
					familyTagMap.set(foundFamily.name, {
						familyTagId: ruleTag.family_tag_id,
						name: foundFamily.name,
						ruleTags: [
							{
								_id: ruleTag._id,
								name: ruleTag.name,
								point_target: ruleTag.point_target,
								totalPointUserEarned: ruleTag.totalStoryPoint,
							},
						],
					});
				}
			});
			return familyTagMap;
		},
		calculateScorePerFamilyTag(ruleTagsMap, familyTagsArr) {
			if (familyTagsArr == null) return;
			const familyTagMap = this.buildFamilyTagsMap(ruleTagsMap, familyTagsArr);

			const familyNames = this.chartOptions.xaxis.categories;
			const percentagePerFamilyTag = [];
			familyNames.forEach((name) => {
				const familyAndRulesObj = familyTagMap.get(name);
				if (familyAndRulesObj == null) return;
				const totalFamilyScore = familyAndRulesObj.ruleTags.length * 150;
				const totalScoreUserEarned = familyAndRulesObj.ruleTags.reduce(
					(total, item) => total + item.totalPointUserEarned,
					0
				);
				percentagePerFamilyTag.push(Math.round((totalScoreUserEarned * 100) / totalFamilyScore));
			});
			this.updateChartData(percentagePerFamilyTag);
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped></style>
