<template>
	<div class="statistic-container">
		<h3 class="ortho-results-title mb-1">Règles</h3>
		<p class="ortho-description ortho-text-gray">Les points gagnés sur les règles vues sur le parcours</p>
		<div
			class="radar-block text-center"
			:class="{ 'pt-5': $vuetify.breakpoint.mdAndUp, 'pt-3 pb-3': $vuetify.breakpoint.smAndDown }"
		>
			<FamilyTagRadarChart v-if="$vuetify.breakpoint.mdAndUp" :ruleTagsMap="ruleTagsMap" />
			<FamilyTagsBarChart v-else :ruleTagsMap="ruleTagsMap" />
		</div>
		<p class="ortho-text-gray text-center pt-3 pb-3">🔎 <b>Statut des règles vues sur le parcours</b></p>
		<div class="d-flex flex-column align-center">
			<div :class="{ 'd-flex mb-5': $vuetify.breakpoint.lgAndUp }">
				<RuleStatisticCard
					:class="{ 'mb-5': $vuetify.breakpoint.mdAndDown, 'mr-5': $vuetify.breakpoint.lgAndUp }"
					:title="'Non acquises'"
					:icon="'help-circle-outline'"
					:backgroudColor="'#BEE6DF'"
					:denominator="ruleSummaryObj ? ruleSummaryObj.totalRules : null"
					:numerator="ruleSummaryObj ? ruleSummaryObj.countNotMasterRule : null"
				/>
				<RuleStatisticCard
					:class="{ 'mb-5': $vuetify.breakpoint.mdAndDown }"
					:title="'Maîtrisées'"
					:icon="'check-circle'"
					:backgroudColor="'#73E7D2'"
					:denominator="ruleSummaryObj ? ruleSummaryObj.totalRules : null"
					:numerator="ruleSummaryObj ? ruleSummaryObj.countMasteredRule : null"
				/>
			</div>
			<RuleStatisticCard
				class="mb-5"
				:title="'Mémorisées'"
				:icon="'star'"
				:backgroudColor="'#FDD938'"
				:denominator="ruleSummaryObj ? ruleSummaryObj.totalRules : null"
				:numerator="ruleSummaryObj ? ruleSummaryObj.countMemorizedRule : null"
			/>
		</div>
	</div>
</template>

<script>
import RuleStatisticCard from './RuleStatisticCard';
import FamilyTagRadarChart from './FamilyTagRadarChart';
import FamilyTagsBarChart from '@/components/charts/FamilyTagsBarChart.vue';

export default {
	name: 'RulesStatistics',
	components: { RuleStatisticCard, FamilyTagRadarChart, FamilyTagsBarChart },
	props: { parcours: Object },
	data() {
		return { ruleTagsMap: null, ruleSummaryObj: null };
	},
	created() {
		if (this.parcours == null) return;
		this.summarizeRulesLevelAndUpdateState(this.parcours);
	},
	watch: {
		parcours(newPropsParcours) {
			if (newPropsParcours == null) return;
			this.summarizeRulesLevelAndUpdateState(newPropsParcours);
		},
	},
	methods: {
		async summarizeRulesLevelAndUpdateState(parcours) {
			const ruleTagsMap = await this.buildRuleTagsMapForParcours(parcours);
			this.ruleTagsMap = ruleTagsMap;
			this.ruleSummaryObj = this.countMasterLevelRules(ruleTagsMap);
		},

		buildStoryMapFromCategoryArray(categories) {
			const storyMap = new Map();
			categories.forEach((cat) => {
				cat.exercises.forEach((exercise) => {
					exercise.usersScores.sort((a, b) => new Date(b.update_date).getTime() - new Date(a.update_date).getTime()); // Sort update_at DESC
					const lastestUserScore = exercise.usersScores[0];
					if (lastestUserScore == null) return;
					lastestUserScore.story_score.forEach((storyScore) => {
						const storyId = storyScore.story_id;
						const totalCorrectAnwser = storyScore.questions_id.filter(({ is_good_answer }) => is_good_answer === true)
							.length;
						if (storyMap.has(storyId)) storyMap.set(storyId, storyMap.get(storyId) + totalCorrectAnwser * 10);
						else storyMap.set(storyId, totalCorrectAnwser * 10);
					});
				});
			});
			return storyMap;
		},

		async buildRuleTagsMapForParcours(parcours) {
			const storyMap = this.buildStoryMapFromCategoryArray(parcours.categories);
			const stories = await this.$store.dispatch('stories/getStoriesByIds', { storyIds: [...storyMap.keys()] });
			const ruleTagsMap = new Map();

			stories.forEach((story) => {
				if (ruleTagsMap.has(story.rule_tags_id._id)) {
					const valueObj = ruleTagsMap.get(story.rule_tags_id._id);
					valueObj.totalStoryPoint = valueObj.totalStoryPoint + storyMap.get(story._id);
					ruleTagsMap.set(story.rule_tags_id._id, valueObj);
				} else {
					ruleTagsMap.set(story.rule_tags_id._id, { ...story.rule_tags_id, totalStoryPoint: storyMap.get(story._id) });
				}
			});

			return ruleTagsMap;
		},

		countMasterLevelRules(ruleTagsMap) {
			let countNotMasterRule = 0;
			let countMasteredRule = 0;
			let countMemorizedRule = 0;

			ruleTagsMap.forEach((value) => {
				if (value.totalStoryPoint < 100) ++countNotMasterRule;
				if (value.totalStoryPoint >= 100 && value.totalStoryPoint < 150) ++countMasteredRule;
				if (value.totalStoryPoint >= 150) ++countMemorizedRule;
			});

			return { countNotMasterRule, countMasteredRule, countMemorizedRule, totalRules: ruleTagsMap.size };
		},
	},
};
</script>

<style lang="scss" scoped>
.statistic-container {
	border-radius: 8px;
	padding: 1rem 2rem;
	background-color: white;

	.radar-block {
		pointer-events: none;
	}
}
</style>
