var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading === false)?_c('div',{staticClass:"divProgressBar mx-auto"},[_c('v-progress-linear',{class:{ 'mt30 mb70': _vm.$vuetify.breakpoint.mdAndUp, 'mt-10 mb-8': _vm.$vuetify.breakpoint.smAndDown },attrs:{"value":_vm.valueProgressBar,"height":"10","color":"#34e5c8","rounded":"","buffer-value":"95","stream":"","background-color":"#F1F1F1"}}),_c('div',{staticClass:"steps beginnerStep text-center"},[_c('span',{class:{ fs15: _vm.$vuetify.breakpoint.mdAndUp, fs10: _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"transparent"}},[_vm._v(".")]),_c('div',{staticClass:"circle",class:{ plainOpacity: _vm.successStep > 0 }})]),_vm._l((_vm.statuts),function(element,index){return _c('div',{key:index,staticClass:"steps text-center",style:({ left: _vm.stepPosition(element.palier_to_reach) })},[_c('span',{class:{
					plainOpacityStatus: _vm.successStep < element.palier_to_reach,
					fs15: _vm.$vuetify.breakpoint.mdAndUp,
					fs10: _vm.$vuetify.breakpoint.smAndDown,
				}},[_vm._v(" "+_vm._s(element.name)+" ")]),(index != _vm.statuts.length - 1)?_c('div',{staticClass:"circle mx-auto",class:{ plainOpacity: _vm.successStep >= element.palier_to_reach }}):_vm._e(),(index != _vm.statuts.length - 1)?_c('span',{staticClass:"ortho-text-gray",class:{
					plainOpacityStatus: _vm.successStep < element.palier_to_reach,
					fs15: _vm.$vuetify.breakpoint.mdAndUp,
					fs10: _vm.$vuetify.breakpoint.smAndDown,
				}},[_vm._v(_vm._s(element.palier_to_reach)+" "),_c('img',{staticClass:"lightIconGrey",attrs:{"src":require("@/assets/icons/lightening-grey.svg")}})]):_vm._e()])})],2):_c('ProgressionBarSkeleton')],1)}
var staticRenderFns = []

export { render, staticRenderFns }