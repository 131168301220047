<template>
	<div class="total-points-container">
		<h3 class="ortho-results-title mb-1">Point gagnés</h3>
		<p class="ortho-description ortho-text-gray">Les points gagnés sur les règles vues sur le parcours</p>
		<div class="progression-block mx-auto pt-1 pb-2">
			<ProgressionBarChart :parcours="parcours" />
		</div>
	</div>
</template>

<script>
import ProgressionBarChart from '@/components/charts/ProgressionBarChart';

export default {
	name: 'TotalEarnedPoints',
	components: { ProgressionBarChart },
	props: {
		parcours: Object,
	},
};
</script>

<style lang="scss" scoped>
.total-points-container {
	border-radius: 8px;
	padding: 1rem 2rem;
	background-color: white;

	.progression-block {
		max-width: 600px;
	}
}
</style>
