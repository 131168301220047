<template>
	<div>
		<div v-for="(label, index) in labels" :key="label">
			<strong class="mb-2">{{ label }}</strong>
			<v-progress-linear :value="scores[index]" height="13" color="#15c39a" rounded background-color="#F1F1F1">
				<strong class="fs10">{{ scores[index] }}%</strong>
			</v-progress-linear>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defaultFamilyTagRadarChartConfig } from '@/components/reports/parcours/chartConfig.js';

export default {
	name: 'FamilyTagsBarChart',
	props: {
		ruleTagsMap: Map,
	},
	data() {
		return { labels: [], scores: [] };
	},
	computed: {
		...mapGetters('familyTags', { familyTags: 'familyTags' }),
	},
	async created() {
		if (this.familyTags == null) await this.$store.dispatch('familyTags/getAllFamilyTags');
		if (this.ruleTagsMap == null) return;
		this.calculateScorePerFamilyTag(this.ruleTagsMap, this.familyTags);
	},
	watch: {
		ruleTagsMap(newVal) {
			this.calculateScorePerFamilyTag(newVal, this.familyTags);
		},
	},
	methods: {
		buildFamilyTagsMap(ruleTagsMap, familyTagsArr) {
			const familyTagMap = new Map(); // Key is family name
			ruleTagsMap.forEach((ruleTag) => {
				const foundFamily = familyTagsArr.find((tag) => tag._id === ruleTag.family_tag_id);
				if (familyTagMap.has(foundFamily.name)) {
					familyTagMap.get(foundFamily.name).ruleTags.push({
						_id: ruleTag._id,
						name: ruleTag.name,
						point_target: ruleTag.point_target,
						totalPointUserEarned: ruleTag.totalStoryPoint,
					});
				} else {
					familyTagMap.set(foundFamily.name, {
						familyTagId: ruleTag.family_tag_id,
						name: foundFamily.name,
						ruleTags: [
							{
								_id: ruleTag._id,
								name: ruleTag.name,
								point_target: ruleTag.point_target,
								totalPointUserEarned: ruleTag.totalStoryPoint,
							},
						],
					});
				}
			});
			return familyTagMap;
		},
		calculateScorePerFamilyTag(ruleTagsMap, familyTagsArr) {
			if (familyTagsArr == null) return;
			const familyTagMap = this.buildFamilyTagsMap(ruleTagsMap, familyTagsArr);

			const familyNames = defaultFamilyTagRadarChartConfig.xaxis.categories;
			const percentagePerFamilyTag = [];
			familyNames.forEach((name) => {
				const familyAndRulesObj = familyTagMap.get(name);
				if (familyAndRulesObj == null) return;
				const totalFamilyScore = familyAndRulesObj.ruleTags.length * 150;
				const totalScoreUserEarned = familyAndRulesObj.ruleTags.reduce(
					(total, item) => total + item.totalPointUserEarned,
					0
				);
				percentagePerFamilyTag.push(Math.round((totalScoreUserEarned * 100) / totalFamilyScore));
			});

			this.labels = familyNames;
			this.scores = percentagePerFamilyTag;
		},
	},
};
</script>

<style lang="scss" scoped></style>
