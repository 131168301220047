/**
 * Calculate global score.
 * @param {Parcours} parcours Not same as parcours model but populated with category, exercises and userscore.
 * @returns
 */
export const calTotalRulePoints = (parcours) => {
	if (parcours == null) return 0;

	let totalPoints = 0;
	parcours.categories.forEach((category) => {
		category.exercises.forEach((exercise) => {
			let highestScoreItem = null;
			exercise.usersScores.forEach((userScore) => {
				if (userScore.score < 0.8) return; // If score of this useScore < 0.8 --> Don't count
				if (highestScoreItem == null) highestScoreItem = userScore;
				else if (new Date(highestScoreItem.update_date).getTime() < new Date(userScore.update_date).getTime())
					highestScoreItem = userScore;
				// Only get latest userScore.
				else return;
				userScore.story_score.forEach((storyScoreItem) => {
					storyScoreItem.questions_id.forEach((question) => {
						if (question.is_good_answer === true) totalPoints = totalPoints + 1;
					});
				});
			});
		});
	});
	return totalPoints * 10;
};
