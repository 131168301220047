<template>
	<div>
		<div v-if="loading === false" class="divProgressBar mx-auto">
			<v-progress-linear
				:value="valueProgressBar"
				height="10"
				color="#34e5c8"
				:class="{ 'mt30 mb70': $vuetify.breakpoint.mdAndUp, 'mt-10 mb-8': $vuetify.breakpoint.smAndDown }"
				rounded
				buffer-value="95"
				stream
				background-color="#F1F1F1"
			></v-progress-linear>
			<div class="steps beginnerStep text-center">
				<span
					:class="{ fs15: $vuetify.breakpoint.mdAndUp, fs10: $vuetify.breakpoint.smAndDown }"
					style="color: transparent"
					>.</span
				>
				<div class="circle" :class="{ plainOpacity: successStep > 0 }"></div>
			</div>
			<div
				v-for="(element, index) in statuts"
				:key="index"
				class="steps text-center"
				:style="{ left: stepPosition(element.palier_to_reach) }"
			>
				<span
					:class="{
						plainOpacityStatus: successStep < element.palier_to_reach,
						fs15: $vuetify.breakpoint.mdAndUp,
						fs10: $vuetify.breakpoint.smAndDown,
					}"
				>
					{{ element.name }}
				</span>
				<div
					class="circle mx-auto"
					:class="{ plainOpacity: successStep >= element.palier_to_reach }"
					v-if="index != statuts.length - 1"
				></div>
				<span
					class="ortho-text-gray"
					:class="{
						plainOpacityStatus: successStep < element.palier_to_reach,
						fs15: $vuetify.breakpoint.mdAndUp,
						fs10: $vuetify.breakpoint.smAndDown,
					}"
					v-if="index != statuts.length - 1"
					>{{ element.palier_to_reach }}
					<img class="lightIconGrey" src="@/assets/icons/lightening-grey.svg" />
				</span>
			</div>
		</div>
		<ProgressionBarSkeleton v-else />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressionBarSkeleton from '@/components/skeletons/ProgressionBarSkeleton';
import { calTotalRulePoints } from '@/utils/score';

export default {
	name: 'ProgressionBarChart',
	components: { ProgressionBarSkeleton },
	props: { parcours: Object },
	data() {
		return { globalCurrentScore: calTotalRulePoints(this.parcours), loading: true };
	},
	async created() {
		if (this.parcours == null) return;
		await this.$store.dispatch('statuts/getStatutsParcours', { idParcours: this.parcours._id });
		this.loading = false;
	},
	computed: {
		...mapGetters('statuts', {
			statuts: 'statuts',
		}),
		valueProgressBar() {
			return this.globalCurrentScore / 120;
		},
		successStep() {
			return this.valueProgressBar * 120;
		},
	},
	watch: {
		async parcours(newParcoursProps) {
			await this.$store.dispatch('statuts/getStatutsParcours', { idParcours: newParcoursProps._id });
			this.loading = false;
			this.globalCurrentScore = calTotalRulePoints(this.parcours); // When props 'parcours' change --> re-update globalCurrentScore
		},
	},
	methods: {
		stepPosition(value) {
			return (value / 12000) * 100 + '%';
		},
	},
};
</script>

<style lang="scss" scoped>
.divProgressBar {
	position: relative;
	.steps {
		position: absolute;
		top: -1.8rem;
		z-index: 0;
		.circle {
			width: 1.2rem;
			height: 1.2rem;
			border-radius: 100%;
			border: 1px solid #f1f1f1;
			background-color: #f1f1f1;
		}
		.trophy {
			width: 1.2rem;
			height: 1.2rem;
		}
	}
	* {
		font-family: 'Lato';
	}
}
.plainOpacity {
	border: 1px solid #34e5c8 !important;
	background-color: #34e5c8 !important;
}
.plainOpacityStatus {
	opacity: 0.2;
}
.lightIconGrey {
	margin-left: -2px;
	height: 12px;
}
::v-deep {
	.v-progress-linear__stream {
		color: #b5a5a5 !important;
		caret-color: #b5a5a5 !important;
	}
}
@media screen and (max-width: 600px) {
	.divProgressBar {
		.steps {
			position: absolute;
			top: -1.7rem;
			z-index: 0;
			.circle {
				width: 1rem;
				height: 1rem;
				border-radius: 100%;
				border: 1px solid #f1f1f1;
				background-color: #f1f1f1;
			}
			.trophy {
				width: 1.2rem;
				height: 1.2rem;
			}
		}
		.lightIconGrey {
			margin-left: -1px;
			height: 8px;
		}
	}
}
</style>
