export const defaultFamilyTagRadarChartConfig = {
	chart: { toolbar: { show: false } },
	colors: ['#15c39a'],
	dataLabels: { enabled: true, formatter: (val) => val + '%', style: { fontSize: '14px' } },
	plotOptions: { radar: { polygons: { strokeColor: '#e9e9e9', fill: { colors: ['#f8f8f8', '#fff'] } } } },
	tooltip: { y: { formatter: (val) => val + '/100' } },
	xaxis: {
		categories: ['Accords', 'Conjugaison', 'Grammaire', 'Homonymes', 'Syntaxe'],
		labels: { style: { fontSize: '15px' } },
	},
	yaxis: {
		max: 100,
		min: 0,
		tickAmount: 10,
		labels: {
			formatter: (val, i) => {
				if (i % 2 === 0) return Math.round(val) + '%';
				else return '';
			},
		},
	},
};
