import { render, staticRenderFns } from "./ProgressionBarChart.vue?vue&type=template&id=2ba30442&scoped=true&"
import script from "./ProgressionBarChart.vue?vue&type=script&lang=js&"
export * from "./ProgressionBarChart.vue?vue&type=script&lang=js&"
import style0 from "./ProgressionBarChart.vue?vue&type=style&index=0&id=2ba30442&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba30442",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VProgressLinear})
